import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/feiten-over-diarree/"> Feiten over diarree </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips voor behandeling
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/oorzaken-van-diarree/">
                  Oorzaken van diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/stress-en-diarree/">
                  Stress en diarree
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/stress-en-diarree/hoge-werklast/">
                      Hoge werkdruk
                    </a>
                  </li>
                  <li className="last">
                    <a href="/feiten-over-diarree/stress-en-diarree/diarree-examen/">
                      Examensituaties
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/feiten-over-diarree/buikgriep/">
                  Virale of bacteriële infectie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/norovirus/">Norovirus</a>
              </li>
              <li className="active">
                <a href="/feiten-over-diarree/diarree-tijdens-uw-menstruatie/">
                  Diarree tijdens de menstruatie
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/chronische-diarree/">
                  Chronische Diarree
                </a>
              </li>
              <li>
                <a href="/feiten-over-diarree/eenartsraadplegen/">
                  Een arts raadplegen?
                </a>
              </li>
              <li className="last">
                <a href="/feiten-over-diarree/gezonde-eetgewoonten/">
                  Gezonde eetgewoonten
                </a>
                <ul>
                  <li className="first">
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselallergie235n/">
                      Voedselallergieën
                    </a>
                  </li>
                  <li>
                    <a href="/feiten-over-diarree/gezonde-eetgewoonten/voedselvergiftiging/">
                      Voedselvergiftiging
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Hoe werkt IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Hoe werkt IMODIUM®?"
                  />
                </div>
                <p>
                  Volgend filmpje toont je de werking van{" "}
                  <strong>
                    IMODIUM<sup>®</sup>
                  </strong>
                  : hoe <strong>diarree</strong> belangrijke vloeistoffen en
                  mineralen aan je lichaam onttrekt en hoe{" "}
                  <strong>
                    IMODIUM<sup>®</sup>{" "}
                  </strong>
                  het natuurlijke evenwicht in je darm herstelt.
                </p>
                <a href="/hoe-werkt-imodium-instant/">Meer informatie</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-30">
            <h1>Diarree tijdens de menstruatie</h1>
            <div className="img">
              <img
                src="/assets/files/pages/chronische-diarree.jpeg"
                width="701"
                height="289"
                alt="Diarree tijdens de menstruatie"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Diarree tijdens de menstruatie komt vaak voor.</h2>
              </div>
              <div className="nine columns">
                <p>
                  Alsof je menstruatie al niet vervelend genoeg is, worden
                  sommige vrouwen tijdens hun menstruatie ook nog eens geplaagd
                  door acute diarree. Slechts weinigen kennen echter het verband
                  tussen <strong>diarree en menstruatie</strong>.
                </p>
              </div>
            </div>
            <h3>Hoe vaak komt diarree bij menstruatie voor?</h3>
            <p>
              Wetenschappelijke literatuur leert ons dat één op vier vrouwen
              last heeft van acute diarree tijdens hun menstruatie. Men vermoedt
              dat de hormonale veranderingen tijdens de menstruatie zorgen voor
              een verhoogde darmperistaltiek (= darmbewegingen).
            </p>
            <p>
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                Referenties:
                <br />
                Hughes S, Higgs N B, Turnberg L A, 1984, Loperamide has
                antisecretory activity in the human jejunum in vivo, Gut, 25,
                931-935.
              </span>
              <br />
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                Karim S. M. M. &amp; Adaikan P.G., 1977 (feb.), The effect of
                loperamide on prostaglandin induced diarrhea in rat and man,
                Prostaglandins, vol. 13 no. 2
              </span>
              <br />
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                Press A.G., Ewe K., Schmidt J. &amp; Junge H., 1990, Effect of
                Loperamide on jejuna electrolyte and water transport,
                prostaglandin E 2-induced secretion and intestinal transit time
                in man, Eur. J. Clin Pharmacol 41 : 239-243.&nbsp;
              </span>
              <br />
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                Kane, 1998, The Menstrual Cycle and Its Effect on Inflammatory
                Bowel Disease and Irritable Bowel Syndrome: A&nbsp;
              </span>
              <span
                style={{
                  "font-size": "10px"
                }}
              >
                Prevalence Study, American Journal of Gastroenterology)
              </span>
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
